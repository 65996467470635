<template>
  <b-row>
    <!-- Boosts -->
    <b-col md="4" v-for="boost in userBoosts.boosts" :key="boost.id" class="">
      <b-card>
        <!-- Quantifiable -->
        <b-card no-body>
          <div>
            <div v-if="!boost.isQuantifiable">
              <div class="d-flex justify-content-md-start align-items-center">
                <b-avatar class="mb-1" :variant="`light-info`" size="45">
                  <feather-icon size="21" :icon="boostIcon(boost.type)" />
                </b-avatar>
                <h2 class="mb-20 px-1 font-weight-bolder">
                  {{ boost.title }}
                </h2>
              </div>
              <div v-if="boost.isRefundable">
                <div class="truncate d-flex justify-content-md-start align-items-center">
                  <div class="d-flex justify-content-between align-items-center">
                    <div class="px-1 font-weight-bolder">Boost price:</div>
                    <div class="px-1 badge  font-medium-4 bg-light-warning rounded-pill">{{ boost.price.amount }}</div>
                  </div>
                </div>
                <b-button @click="refundBoost(boost)" class="my-1" variant="primary" :disabled="requestInProgress">
                  <span>Refund Autocollect</span>
                </b-button>
              </div>
            </div>
            <!-- Not Quantifiable -->
            <div v-else>
              <div class="d-flex justify-content-md-start align-items-center">
                <b-avatar class="mb-1" :variant="`light-info`" size="45">
                  <feather-icon size="21" :icon="boostIcon(boost.type)" />
                </b-avatar>
                <h2 class="mb-20 px-1 font-weight-bolder">
                  {{ boost.title }}
                </h2>
              </div>
              <div class="truncate d-flex justify-content-md-start align-items-center">
                <div class="mb-1 mt-1 d-flex justify-content-between align-items-center">
                  <span class="px-1 font-weight-bolder">Number of Packs:</span>
                  <div class="px-1 badge  font-medium-4 bg-light-info rounded-pill">
                    <span>{{ numberOfPack(boost) }}</span>
                  </div>
                </div>
              </div>
              <!-- List of packs -->
              <div class="overflow-hidden" style="max-height: 165px">
                <ul class="px-0 overflow-hidden" v-for="pack in boost.packs" :key="pack.id">
                  <li v-if="pack.isRefundable" class="d-flex gap-1 justify-content-md-start align-items-center">
                    <b-button
                      @click="refundPack(boost, pack)"
                      class="m-1"
                      variant="primary"
                      :disabled="requestInProgress"
                    >
                      <span>Refund</span>
                    </b-button>
                    <div class="px-1 badge  font-medium-4 bg-light-warning rounded-pill">
                      <span>{{ pack.price.amount }}</span>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </b-card>
      </b-card>
    </b-col>
    <!-- Boosts ends -->
    <b-col md="4">
      <b-card no-body>
        <b-card-body class="">
          <b-avatar class="mb-1" :variant="`light-info`" size="45">
            <feather-icon size="21" icon="CreditCardIcon" />
          </b-avatar>

          <div class="truncate">
            <h2 class="mb-25 font-weight-bolder">
              Limits
            </h2>
            <h3 class="mb-25 font-weight-bolder">
              CeFi
            </h3>
            <!--            <span-->
            <!--              >Daily In - $-->
            <!--              {{ $decimal(userLimit.cefi.dayLimitIn - userLimit.cefi.leftDayLimitIn).toDecimalPlaces(2) }} /-->
            <!--              {{ userLimit.cefi.dayLimitIn }}</span-->
            <!--            ><br />-->
            <span v-if="userLimit.cefi"
              >Daily Out - $
              {{ $decimal(userLimit.cefi.dayLimitOut - userLimit.cefi.leftDayLimitOut).toDecimalPlaces(2) }} /
              {{ userLimit.cefi.dayLimitOut }}</span
            ><br />
            <!--            <span-->
            <!--              >Monthly In - $-->
            <!--              {{ $decimal(userLimit.cefi.monthLimitIn - userLimit.cefi.leftMonthLimitIn).toDecimalPlaces(2) }} /-->
            <!--              {{ userLimit.cefi.monthLimitIn }}</span-->
            <!--            ><br />-->
            <span v-if="userLimit.cefi"
              >Monthly Out - $
              {{ $decimal(userLimit.cefi.monthLimitOut - userLimit.cefi.leftMonthLimitOut).toDecimalPlaces(2) }} /
              {{ userLimit.cefi.monthLimitOut }}</span
            ><br />
            <b-button @click="toggleCeFiModal" class="my-1" variant="primary">
              Edit CeFi Limits
            </b-button>
            <b-modal v-model="isModalOpened" centered hide-footer>
              <EditLimitsModal :type="limitsType" :title="limitsModalTitle" @close="onLimitsModalClose" />
            </b-modal>
            <!--            <h3 class="mt-25 mb-25 font-weight-bolder">-->
            <!--              DeFi-->
            <!--            </h3>-->
            <!--            <span-->
            <!--              >Daily In - $-->
            <!--              {{ $decimal(userLimit.defi.dayLimitIn - userLimit.defi.leftDayLimitIn).toDecimalPlaces(2) }} /-->
            <!--              {{ userLimit.defi.dayLimitIn }}</span-->
            <!--            ><br />-->
            <!--            <span-->
            <!--              >Daily Out - $-->
            <!--              {{ $decimal(userLimit.defi.dayLimitOut - userLimit.defi.leftDayLimitOut).toDecimalPlaces(2) }} /-->
            <!--              {{ userLimit.defi.dayLimitOut }}</span-->
            <!--            ><br />-->
            <!--            <span-->
            <!--              >Monthly In - $-->
            <!--              {{ $decimal(userLimit.defi.monthLimitIn - userLimit.defi.leftMonthLimitIn).toDecimalPlaces(2) }} /-->
            <!--              {{ userLimit.defi.monthLimitIn }}</span-->
            <!--            ><br />-->
            <!--            <span-->
            <!--              >Monthly Out - $-->
            <!--              {{ $decimal(userLimit.defi.monthLimitOut - userLimit.defi.leftMonthLimitOut).toDecimalPlaces(2) }} /-->
            <!--              {{ userLimit.defi.monthLimitOut }}</span-->
            <!--            ><br />-->
          </div>
          <!--          <b-button @click='toggleDeFiModal' class="mt-1" variant="primary">-->
          <!--            Edit DeFi Limits-->
          <!--          </b-button>-->
        </b-card-body>
      </b-card>
    </b-col>

    <b-col md="4" v-for="chart in charts" :key="chart.title">
      <b-card>
        <b-card-title class="mb-1">
          {{ chart.title }}
        </b-card-title>

        <vue-apex-charts type="donut" height="350" :options="chart.chart.chartOptions" :series="chart.chart.series" />
      </b-card>
    </b-col>

    <b-col cols="12" v-if="hasWallets">
      <b-card>
        <b-card-body>
          <b-table-simple hover small caption-top responsive>
            <b-thead head-variant="light">
              <b-tr>
                <b-th>cefi/defi</b-th>
                <b-th>Asset</b-th>
                <b-th>Balance</b-th>
                <b-th>$</b-th>
                <b-th>Wallet addres</b-th>
              </b-tr>
            </b-thead>

            <b-tbody>
              <template v-for="item in tableGroup.cefi">
                <b-tr v-for="(subitem, iSub) in item.balances" :key="subitem.id">
                  <b-th v-if="iSub === 0" :rowspan="item.balances.length">Cefi</b-th>
                  <b-th> {{ subitem.coin.emoji }} {{ subitem.coin.name }} / {{ subitem.coin.network || '' }} </b-th>
                  <b-td> {{ subitem.balance }} </b-td>
                  <b-td> $&nbsp;{{ subitem.balanceUsd }} </b-td>
                  <b-td>
                    <b-button variant="link" @click="doCopy(subitem.address)" class="p-1 font-16 text-left">
                      {{ formatLongString(subitem.address) }}
                    </b-button>
                  </b-td>
                </b-tr>
              </template>

              <template v-for="item in tableGroup.defi">
                <b-tr v-for="(subitem, iSub) in item.balances" :key="subitem.id">
                  <b-th v-if="iSub === 0" :rowspan="item.balances.length">Defi / {{ item.name }}</b-th>
                  <b-th> {{ subitem.coin.emoji }} {{ subitem.coin.name }} / {{ subitem.coin.network || '' }} </b-th>
                  <b-td> {{ subitem.balance }} </b-td>
                  <b-td> $&nbsp;{{ subitem.balanceUsd }} </b-td>
                  <b-td>
                    <b-button variant="link" @click="doCopy(subitem.address)" class="p-1 font-16 text-left">
                      {{ formatLongString(subitem.address) }}
                    </b-button>
                  </b-td>
                </b-tr>
              </template>
            </b-tbody>
          </b-table-simple>
        </b-card-body>
      </b-card>
    </b-col>

    <b-col md="12">
      <AppTable
        :rows="rows"
        :columns="columns"
        :is-loading="requestInProgress"
        :pagination="{
          limit: transactions.limit,
          count: transactions.count,
          page: transactions.page,
        }"
        @change-limit="changePageLimit"
        @change-page="changePage"
      >
        <template #prependFilters>
          <b-row class="w-100">
            <b-col md="4" xl="4" class="mb-1">
              <AssetFilter v-model="selectAsset" @change="changeAsset" />
            </b-col>

            <b-col md="4" xl="4" class="mb-1">
              <b-form-group>
                <label for="datepicker-placeholder">Type</label>
                <b-form-select v-model="selectType" :options="typesOptions" @change="changeType" />
              </b-form-group>
            </b-col>

            <b-col md="4" xl="4" class="mb-1">
              <b-form-group>
                <label for="datepicker-placeholder">Status</label>
                <b-form-select v-model="selectStatus" :options="statusOptions" @change="changeStatus" />
              </b-form-group>
            </b-col>

            <b-col class="mb-1">
              <b-form-group>
                <label class="mr-1">Search by address</label>
                <b-form-input v-model="userAddress" placeholder="address" type="text" />
              </b-form-group>
            </b-col>

            <b-col class="mb-1">
              <b-form-group>
                <label class="mr-1">Search by name</label>
                <b-form-input v-model="userTelegramName" placeholder="name" type="text" />
              </b-form-group>
            </b-col>

            <b-col md="1" xl="1" class="mb-1 mt-auto pb-1">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                :disabled="requestInProgress"
                class="mt-auto"
                type="button"
                variant="primary"
                @click="search"
              >
                <feather-icon icon="SearchIcon" size="18" />
              </b-button>
            </b-col>
          </b-row>
        </template>

        <template #default="{ column, row, formattedRow }">
          <span v-if="column.field === 'amount'" class="flex-center-align" :class="row.amount.theme">
            {{ row.amount.text }}
          </span>

          <span v-else-if="column.field === 'asset'" class="asset-cell flex-center-align">
            <img :src="row.asset.image" alt="coin" class="coin-img" />
            <span class="ml-1">{{ row.asset.text }}</span>
          </span>

          <span v-else-if="column.field === 'user'" class="flex-center-align ">
            <b-button variant="link" @click="row.user.action" target="_blank" class="text-left">
              {{ row.user.name }}
            </b-button>
          </span>

          <span v-else-if="column.field === 'status'" class="flex-center-align">
            <b-badge :variant="row.status.theme">
              {{ row.status.text }}
            </b-badge>
          </span>

          <span v-else-if="column.field === 'toAddress'" class="flex-center-align">
            <b-button variant="link" @click="doCopy(row.toAddress.raw)" class="p-1 font-16 text-left">
              {{ row.toAddress.text }}
            </b-button>
          </span>

          <span v-else-if="column.field === 'checkUrl'" class="flex-center-align">
            <a :href="row.checkUrl.raw" target="_blank" class="p-1 ">{{ row.checkUrl.text }}</a>
          </span>

          <!-- default render cell -->
          <span v-else class="flex-center-align">
            {{ formattedRow[column.field] }}
          </span>
        </template>
      </AppTable>
    </b-col>
  </b-row>
</template>
<script>
import {
  BListGroupItem,
  BButton,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BCard,
  BFormSelect,
  BPagination,
  BCardBody,
  BAvatar,
  BSpinner,
  BBadge,
  BTableSimple,
  BTr,
  BTh,
  BTd,
  BThead,
  BTfoot,
  BTbody,
  BCardSubTitle,
  BCardTitle,
} from 'bootstrap-vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { VueGoodTable } from 'vue-good-table'
import vSelect from 'vue-select'

import StatisticCardWithAreaChart from '@/views/apps/analytics-dashboard/charts/StatisticCardWithAreaChart.vue'
import { userTransactions as columns } from '@/views/apps/users/config/tableConfig'
import { initBoosts } from '@/views/apps/users/config/userBoostConfig'

import waitRequest from '@/mixins/waitRequest'
import validationError from '@/mixins/validationError'
import transactions from '@/mixins/transactions'
import VueApexCharts from 'vue-apexcharts'
import { baseDonatChart } from '@/views/apps/users/config/chartsConfig'
import { generateRandomHexColor } from '@/tools/util'
import AppTable from '@/components/AppTable.vue'
import AssetFilter from '@/components/containers/AssetFilter.vue'
import EditLimitsModal from '@/views/apps/users/components/EditLimitsModal.vue'

export default {
  name: 'UserView',
  mixins: [waitRequest, validationError, transactions],
  components: {
    BListGroupItem,
    EditLimitsModal,
    AssetFilter,
    AppTable,
    VueApexCharts,
    BCardTitle,
    BCardSubTitle,
    vSelect,
    BBadge,
    BSpinner,
    BAvatar,
    BCardBody,
    BPagination,
    BFormSelect,
    StatisticCardWithAreaChart,
    BRow,
    BCol,
    BCard,
    VueGoodTable,
    BButton,
    BFormInput,
    BFormGroup,

    BTableSimple,
    BTr,
    BTh,
    BTd,
    BThead,
    BTfoot,
    BTbody,
  },
  data() {
    return {
      columns,
      userId: null,
      userLimit: {},
      userWallet: {},
      isModalOpened: false,
      limitsType: 'cefi',
      wallet: null,
      userBoosts: [],
      refundStatus: null,
      baseDonatChart,
    }
  },
  computed: {
    ...mapGetters({
      transactions: 'users/transactions',
    }),

    charts() {
      if (!this.wallet) return []
      return this.createCharts(this.wallet)
    },

    rows() {
      return this.transactions.value?.map(this.transactionsDataAdapter)
    },

    hasHoldBoosts() {
      return this.userBoosts?.length
    },
    hasWallets() {
      return this.wallet && Object.keys(this.wallet).length
    },

    tableGroup() {
      const tableGoups = Object.groupBy(this.wallet, ({ isCeFi }) => isCeFi)
      const [cefi, defi] = Object.values(tableGoups)

      return { cefi, defi }
    },

    limitsModalTitle() {
      return this.limitsType === 'cefi' ? 'Edit CeFi Limits' : 'Edit DeFi Limits'
    },
  },
  created() {
    this.initState()
  },
  beforeDestroy() {
    this.clearState()
  },
  methods: {
    generateRandomHexColor,
    ...mapMutations({ clearTransactions: 'users/fetchUserTransactions' }),
    ...mapActions({
      fetchUserWallets: 'users/fetchUserWallets',
      fetchUserLimits: 'users/fetchUserLimits',
      fetchUserTransactions: 'users/fetchUserTransactions',
      getBoosts: 'users/getBoosts',
      fetchRefundBoost: 'users/fetchRefundBoost',
    }),

    async initState() {
      if (!this.$route.params.id) return
      this.userId = this.$route.params.id

      await this.getUserBoosts(this.userId)
      await this.getWallet(this.userId)
      await this.getLimits(this.userId)

      const limit = this.selectPerPage
      const query = this.createQueryConfig({ limit })
      await this.getTransactions(query)
      await this.getCurrency()
    },

    clearState() {
      this.userId = null
      this.userLimit = {}
      this.userWallet = {}
      this.userBoosts = initBoosts
      this.clearTransactions()
    },

    createQueryConfig(config) {
      return {
        id: this.userId,
        page: this.transactions.page,
        limit: this.transactions.limit,

        userAddress: this.userAddress || undefined,
        userTelegramName: this.userTelegramName || undefined,
        coinId: this.selectAsset?.id || undefined,
        type: this.selectType || undefined,
        status: this.selectStatus || undefined,

        ...config,
      }
    },

    getWallet(id) {
      return this.waitRequest(() => {
        return this.fetchUserWallets({ id })
          .then(response => {
            this.wallet = response.data
          })
          .catch(this.checkErrors)
      })
    },

    getLimits(id) {
      return this.waitRequest(() => {
        return this.fetchUserLimits({ id })
          .then(response => {
            this.userLimit = response.data
          })
          .catch(this.checkErrors)
      })
    },

    fetchUserRefunds(boost, packId) {
      const userId = this.userId
      const boostId = boost.id
      return this.waitRequest(() => {
        return this.fetchRefundBoost({ userId, boostId, packId })
          .then(response => {
            this.refundStatus = response.status
          })
          .catch(this.checkErrors)
      })
    },

    getUserBoosts(id) {
      this.userBoosts = initBoosts
      return this.waitRequest(() =>
        this.getBoosts({ id })
          .then(response => this.updateBoosts(initBoosts, response.data?.boosts || []))
          .catch(this.checkErrors),
      )
    },

    numberOfPack(boost) {
      return boost?.packs?.filter(item => item.isRefundable).length || 0
    },

    boostIcon(id) {
      switch (id) {
        case 'farming.autocollect':
          return 'CheckSquareIcon'
        case 'clicker.games':
          return 'PlusSquareIcon'
        case 'clicker.retry':
          return 'RepeatIcon'
        default:
          return 'CheckSquareIcon'
      }
    },
    async handleRefundRequest(boost, packId) {
      try {
        await this.fetchUserRefunds(boost, packId)
        if (this.refundStatus === 200) {
          return true
        } else {
          console.error('Failed to update on the server')
          return false
        }
      } catch (error) {
        console.log('Server connection error:', error)
        return false
      } finally {
        this.requestInProgress = false
        this.refundStatus = null
      }
    },

    async refundPack(boost, pack) {
      const isSuccessful = await this.handleRefundRequest(boost, pack.id)
      if (isSuccessful) {
        const packIndex = boost.packs.findIndex(item => item.id === pack.id)
        if (packIndex > -1) {
          boost.packs.splice(packIndex, 1)
          if (boost.packs.filter(item => item.isRefundable).length === 0) {
            boost.isRefundable = false
          }
        }
      }
    },

    async refundBoost(boost) {
      if (boost && !boost.isQuantifiable) {
        const isSuccessful = await this.handleRefundRequest(boost, null)
        if (isSuccessful) {
          boost.isRefundable = false
        }
      } else {
        console.log('Boost not found')
      }
    },

    updateBoosts(existingBoosts, updates) {
      this.userBoosts.boosts = existingBoosts.boosts.map(boost => {
        const update = updates.find(u => u.type === boost.type)
        if (update) {
          return {
            ...boost,
            ...update,
            packs: update.packs || boost.packs,
          }
        }
        return boost
      })
    },
    
    getTransactions(query) {
      return this.waitRequest(() => {
        return this.fetchUserTransactions(query).catch(this.checkErrors)
      })
    },

    createCharts(tables) {
      const chartsGroup = tables
        ?.map(item => {
          return {
            title: item.isCeFi ? 'CeFi' : `DeFi / ${item.name}`,

            item: item.balances?.map(balance => {
              return {
                title: item.isCeFi ? 'CeFi' : `DeFi / ${item.name}`,
                item: parseFloat(balance.balance),
                color: this.generateRandomHexColor(),
                label: `${balance.coin?.emoji || ''} ${balance.coin?.name || ''} / ${balance.coin?.network || ''}`,
              }
            }),
          }
        })
        .reduce((acc, obj) => {
          return acc.concat(obj.item)
        }, [])

      const groupedChartsObject = Object.groupBy(chartsGroup, ({ title }) => title)
      const groupedChartsData = Object.entries(groupedChartsObject)

      const chartsList = groupedChartsData.map(([title, item]) => {
        const chartSeries = item.map(item => item.item)
        const chartLabels = item.map(item => item.label)
        const chartColors = item.map(item => item.color)

        const chart = {
          ...baseDonatChart,
          series: chartSeries,
          chartOptions: {
            ...baseDonatChart.chartOptions,
            labels: chartLabels,
            colors: chartColors,
          },
        }

        return { title: title, chart }
      })

      return chartsList
    },

    toggleCeFiModal() {
      this.limitsType = 'cefi'
      this.isModalOpened = !this.isModalOpened
    },
    toggleDeFiModal() {
      this.limitsType = 'defi'
      this.isModalOpened = !this.isModalOpened
    },

    async onLimitsModalClose() {
      this.isModalOpened = false
      await this.getLimits(this.userId)
    },
  },
}
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-sweetalert.scss';
@import '~@core/scss/vue/libs/chart-apex.scss';

.centered-element {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.font-16 {
  font-size: 1rem;
}

.coin-img {
  width: 30px;
  border-radius: 50%;
}

.asset-cell {
  display: block;
  width: 250px;
}
</style>
